import { EVENT_IRRIGATION_UNITS, RDEventIrrigation } from '@cibo/core'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import { eventValidationSchema } from '../EventDetail'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { EventIrrigationCell } from './EventIrrigationCell'
import { EventIrrigationEditor } from './EventIrrigationEditor'

const TRAIT_ID = 'eventIrrigation'

export const EventIrrigation: ITraitFeatureByIdYear<RDEventIrrigation> = {
  traitId: TRAIT_ID,
  cellDisplay: EventIrrigationCell,
  editor: EventIrrigationEditor,
  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)
    return detail?.input || {}
  },
  ns: '@cibo/landmanager/EventIrrigationEditor',
  validationSchema: eventValidationSchema<RDEventIrrigation['input']>(({ t }) =>
    Yup.object({
      startDate: Yup.date()
        .when('endDate', {
          is: (val: Date | undefined) => !val || isNaN(val?.valueOf()),
          then: (schema: Yup.DateSchema) =>
            schema.required(t('required', { context: 'startDate' })),
          otherwise: (schema: Yup.DateSchema) =>
            schema
              .max(Yup.ref('endDate'), ({ max }) =>
                t('max', {
                  ns: '@cibo/landmanager/EventIrrigationEditor',
                  context: 'startDate',
                  max,
                })
              )
              .required(
                t('required', {
                  ns: '@cibo/landmanager/EventIrrigationEditor',
                  context: 'startDate',
                })
              ),
        })
        .test(
          'dateRequired',
          t('required_fourDigitYear'),
          // @ts-ignore typing doesn't like this.
          (date: string) => dayjs(date).year() > 1969
        )
        .typeError(t('required', { context: 'startDate' })),
      endDate: Yup.date()
        .required(t('required', { context: 'endDate' }))
        .test(
          'dateRequired',
          t('required_fourDigitYear'),
          // @ts-ignore typing doesn't like this.
          (date: string) => dayjs(date).year() > 1969
        )
        .typeError(t('required', { context: 'endDate' })),
      amount: Yup.number()
        .min(0, t('min', { context: 'amount' }))
        .required(t('required', { context: 'amount' }))
        .typeError(t('required', { context: 'amount' })),
      unit: Yup.string()
        .oneOf(EVENT_IRRIGATION_UNITS)
        .required(t('required', { context: 'unit' })),
    })
  ),
}
