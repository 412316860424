// @ts-nocheck
import { SupplyShedFilters } from '@cibo/core'
import { UserDataKey, useUserData } from '@cibo/profile'
import { GridColumnVisibilityModel, GridRowId } from '@mui/x-data-grid-pro'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { UserSupplyShedManagementContext } from '../UserSupplyShedManagement'

export const USER_SUPPLY_SHED_TABLE_PAGE_SIZES = [10, 25, 50, 100]

export interface UserSupplyShedTableState {
  // these convenience functions translate from table-row spec to resourceIds
  onDelete(): void

  selectedRowIds: GridRowId[]
  setSelectedRowIds(ids: GridRowId[]): void
  clearSelectedRowIds(): void

  pageSize: number
  setPageSize(pageSize: number): void
  page: number
  setPage(page: number): void
  columnVisibility: GridColumnVisibilityModel
  setColumnVisibility: Dispatch<SetStateAction<GridColumnVisibilityModel>>
  filters: SupplyShedFilters
  setFilters: (filters: SupplyShedFilters) => void
}

export const UserSupplyShedTableContext = createContext<UserSupplyShedTableState>({
  filters: {},
  setFilters: console.log,
  selectedRowIds: [],
  setSelectedRowIds: console.log,
  clearSelectedRowIds: console.log,

  onDelete: console.log,

  pageSize: USER_SUPPLY_SHED_TABLE_PAGE_SIZES[0],
  setPageSize: console.log,
  page: 0,
  setPage: console.log,
  columnVisibility: {},
  setColumnVisibility: console.log,
})
UserSupplyShedTableContext.displayName = 'UserSupplyShedTableContext'

type UserSupplyShedTableProviderProps = {
  persistKey?: UserDataKey
}

export const UserSupplyShedTableProvider = ({
  children,
  persistKey,
}: PropsWithChildren<UserSupplyShedTableProviderProps>) => {
  const { data, update } = useUserData(persistKey)
  const [isReady, setIsReady] = useState(false)

  const [pageSize, setPageSize] = useState(USER_SUPPLY_SHED_TABLE_PAGE_SIZES[0])
  const [filters, setFilters] = useState<SupplyShedFilters>({})
  const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>({})

  useEffect(() => {
    if (!isReady && data) {
      setPageSize(data.pageSize || USER_SUPPLY_SHED_TABLE_PAGE_SIZES[0])
      setFilters(data.filters || {})
      setColumnVisibility(data.columnVisibility || {})
      setIsReady(true)
    }
  }, [data, isReady])

  useEffect(() => {
    if (isReady) {
      update({
        filters,
        columnVisibility,
        pageSize,
      })
    }
  }, [filters, columnVisibility, pageSize, isReady])

  const [page, setPage] = useState(0)
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
  const clearSelectedRowIds = () => setSelectedRowIds([])

  const { setDeleteModal } = useContext(UserSupplyShedManagementContext)

  return (
    <UserSupplyShedTableContext.Provider
      value={{
        filters,
        setFilters,

        selectedRowIds,
        setSelectedRowIds: (ids: GridRowId[]) => setSelectedRowIds((ids as string[]) || []),
        clearSelectedRowIds,

        pageSize,
        setPageSize,
        page,
        setPage,
        columnVisibility: columnVisibility as GridColumnVisibilityModel,
        setColumnVisibility: setColumnVisibility as Dispatch<
          SetStateAction<GridColumnVisibilityModel>
        >,

        onDelete: () =>
          setDeleteModal({
            open: true,
            selectedIds: selectedRowIds,
            onSuccess: clearSelectedRowIds,
          }),
      }}
    >
      {children}
    </UserSupplyShedTableContext.Provider>
  )
}
