import { CoverCrop, RDCoverCrop, TerminationMethod } from '@cibo/core'
import { identity } from 'ramda'
import { DateSchema, boolean, date, object, ref, string } from 'yup'

import dayjs from 'dayjs'
import { ITraitFeatureByIdYear } from '../types'
import { isDetailRequired } from '../useIsRequired'
import { findInitialValuesDetail } from '../utils'
import { CoverCropCell } from './CoverCropCell'
import { CoverCropCellEditor } from './CoverCropCellEditor'
import { CoverCropEditor } from './CoverCropEditor'
import { CoverCropResultsOnlyEditor } from './CoverCropResultsOnlyEditor'
import { CoverCropSimpleCell } from './CoverCropSimpleCell'

const TRAIT_ID = 'coverCrop'

const CoverCropBase: ITraitFeatureByIdYear<RDCoverCrop> = {
  traitId: TRAIT_ID,
  ns: '@cibo/landmanager/CoverCropEditor',
  cellDisplay: CoverCropCell,
  editor: CoverCropEditor,
  initialValues: () => false,
  SimpleCellView: CoverCropSimpleCell,
}

export const CoverCropInput: ITraitFeatureByIdYear<RDCoverCrop> = {
  ...CoverCropBase,

  /**
   * plantedAt and terminatedAt must be null for the datepicker to not default to today in MUI v5
   * once updated to v6, we should be able to use `defaultValue` on the picker itself and remove
   * this ts-ignore
   */
  // @ts-ignore
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)
    return !!detail?.input
      ? {
          plantedAt: null,
          terminatedAt: null,
          ...detail.input,
        }
      : {
          plantedAt: null,
          terminatedAt: null,
          crop: detail?.result === false ? 'none' : detail?.result === true ? 'other' : undefined,
        }
  },
  validationSchema: ({ requirement, t }) =>
    object({
      crop: string<CoverCrop>()
        .required(t('required', { context: 'crop' }))
        .typeError(t('required', { context: 'crop' })),
      plantedAt: date()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .test(
          'dateRequired',
          t('required_fourDigitYear'),
          // @ts-ignore typing doesn't like this.
          (date: string) => dayjs(date).year() > 1969
        )
        .when('crop', {
          is: 'none',
          then: (schema: any) => schema.nullable(),
          otherwise: isDetailRequired({ requirement, fieldName: 'plantedAt' })
            ? (schema: DateSchema) =>
                schema.when('terminatedAt', {
                  is: (val: Date | undefined) => !val || isNaN(val?.valueOf()),
                  then: schema
                    .required(t('required', { context: 'plantedAt' }))
                    .typeError(t('required', { context: 'plantedAt' })),
                  otherwise: schema
                    .max(ref('terminatedAt'), ({ max }) => t('max', { context: 'plantedAt', max }))
                    .required(t('required', { context: 'plantedAt' }))
                    .typeError(t('required', { context: 'plantedAt' })),
                })
            : (schema: any) =>
                schema
                  .nullable()
                  .typeError(
                    t('invalid', {
                      context: 'plantedAt',
                    })
                  )
                  .max(ref('terminatedAt'), ({ max }: { max: number }) =>
                    t('max', { context: 'plantedAt', max })
                  ),
        }),
      terminatedAt: date()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .test(
          'dateRequired',
          t('required_fourDigitYear'),
          // @ts-ignore typing doesn't like this.
          (date: string) => dayjs(date).year() > 1969
        )
        .when('crop', {
          is: 'none',
          then: (schema: any) => schema.nullable(),
          otherwise: isDetailRequired({ requirement, fieldName: 'terminatedAt' })
            ? (schema: DateSchema) =>
                schema.when('plantedAt', {
                  is: (val: Date | undefined) => !val || isNaN(val?.valueOf()),
                  then: schema
                    .required(
                      t('required', {
                        context: 'terminatedAt',
                      })
                    )
                    .typeError(
                      t('required', {
                        context: 'terminatedAt',
                      })
                    ),
                  otherwise: schema
                    .min(ref('plantedAt'), ({ min }) =>
                      t('min', {
                        context: 'terminatedAt',
                        min,
                      })
                    )
                    .required(
                      t('required', {
                        context: 'terminatedAt',
                      })
                    )
                    .typeError(
                      t('required', {
                        context: 'terminatedAt',
                      })
                    ),
                })
            : (schema: any) =>
                schema
                  .nullable()
                  .typeError(
                    t('invalid', {
                      context: 'terminatedAt',
                    })
                  )
                  .min(ref('plantedAt'), ({ min }: { min: number }) =>
                    t('min', {
                      context: 'terminatedAt',
                      min,
                    })
                  ),
        }),
      harvestedHay: boolean()
        .transform((current, original) => (current === 'true' ? true : false))
        .when('crop', {
          is: 'none',
          then: identity,
          otherwise: isDetailRequired({ requirement, fieldName: 'harvestedHay' })
            ? (schema: any) =>
                schema
                  .required(
                    t('required', {
                      context: 'harvestedHay',
                    })
                  )
                  .typeError(
                    t('required', {
                      context: 'harvestedHay',
                    })
                  )
            : (schema: any) => schema.nullable(),
        }),
      harvestedGrain: boolean()
        .transform((current, original) => (current === 'true' ? true : false))
        .when('crop', {
          is: 'none',
          then: identity,
          otherwise: isDetailRequired({ requirement, fieldName: 'harvestedGrain' })
            ? (schema: any) =>
                schema
                  .required(
                    t('required', {
                      context: 'harvestedGrain',
                    })
                  )
                  .typeError(
                    t('required', {
                      context: 'harvestedGrain',
                    })
                  )
            : (schema: any) => schema.nullable(),
        }),
      terminationMethod: string<TerminationMethod>().when('crop', {
        is: 'none',
        then: identity,
        otherwise: isDetailRequired({ requirement, fieldName: 'terminationMethod' })
          ? (schema: any) =>
              schema.required(
                t('required', {
                  context: 'terminationMethod',
                })
              )
          : (schema: any) => schema.optional(),
      }),
    }).required(t('required')),
}

export const CoverCropResult: ITraitFeatureByIdYear<RDCoverCrop> = {
  ...CoverCropBase,
  cellEditor: CoverCropCellEditor,
  editor: CoverCropResultsOnlyEditor,

  // @ts-ignore complicated to make this work for results only
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)

    return typeof detail?.result !== 'undefined'
      ? {
          result: detail.result === true ? 'true' : 'false',
        }
      : {
          result: '',
        }
  },

  // @ts-ignore complicated to make this work for results only
  validationSchema: ({ t }) =>
    object({
      result: boolean().required(t('required')),
    }),
  detailFromEditorValues: (formValues, requirement) => ({
    traitId: TRAIT_ID,
    year: requirement?.year,
    result: formValues.input.result,
  }),
}
