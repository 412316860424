import { GlobalMpAuth } from '@cibo/core'
import { PDFPage } from '@cibo/ui'
import { Box, Stack, Typography } from '@mui/material'
import Image from 'mui-image'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { ProgramEngagementProvider, useProgramEngagementContext } from '../../context'
import { useProgramEngagement } from '../../queries'
// @ts-ignore tsconfig needs help for svg imports
import imgUrl from './CiboLogo.svg'
import { SelectedPracticesTable } from './SelectedPracticesTable'

export const EqipSelectedPracticesPDF = () => {
  const { engagementId } = useParams()

  const [params] = useSearchParams({ authorization: '' })
  const authToken = params.get('authToken')

  const [authReady, setAuthReady] = useState(false)

  useEffect(() => {
    if (!authToken) return
    GlobalMpAuth.setAuthToken(authToken)
    setAuthReady(true)
  }, [authToken])

  const engagement = useProgramEngagement(
    { id: engagementId },
    { enabled: !!engagementId && !!authReady }
  )

  if (!engagementId) return null

  return (
    <ProgramEngagementProvider
      basePath={''}
      engagementId={engagementId}
      userId={Number.NaN}
      programId={engagement.data?.programId ?? ''}
    >
      <PDFPage>
        <Header />

        <SelectedPracticesTable
          engagementId={engagementId}
          detailRequirements={[{ dataType: 'field', traitId: 'eqipEligibilityFarmInfo' }]}
          resourceIds={engagement.data?.fields ?? []}
        />
      </PDFPage>
    </ProgramEngagementProvider>
  )
}

const Header = () => {
  const { t } = useTranslation('@cibo/programs/EqipSelectedPracticesPDF')
  const { ownerName } = useProgramEngagementContext()

  return (
    <Stack style={{ pageBreakAfter: 'always' }} spacing={3}>
      <Box style={{ width: '150px' }}>
        <Image src={imgUrl} style={{ display: 'block', width: '150px' }} />
      </Box>

      <Typography variant="h6">{t('title', { farmerName: ownerName })}</Typography>
      <Typography>{t('cta', { farmerName: ownerName })}</Typography>
    </Stack>
  )
}
