import { FieldModel } from '@cibo/core'
import { useFigures } from '@cibo/ui'
import { Stack, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { TFunction } from 'i18next'
import { LandThumbnail, LandThumbnailSkin } from '../LandThumbnail'

type CellProps = { hideAcreage?: boolean; hideLocation?: boolean; thumbnailSize?: number }

export const fieldColumnAttributes = (field: FieldModel) => ({
  id: field.resourceId,
  geometrySlug: field.geometrySlug,
  state: field.state,
  name: field.name,
  county: field.county,
  acres: field.acres,
  ownerId: field.ownerId,
})

export type FieldNameCellFieldValues = ReturnType<typeof fieldColumnAttributes>

export const FieldNameCell = ({
  row,
  hideAcreage = false,
  hideLocation = true,
  thumbnailSize = 68,
}: CellProps & GridRenderCellParams<FieldModel>) => {
  const { acres } = useFigures()

  return (
    <Stack direction="row" spacing={1.5} paddingY={0.75} alignItems={'center'}>
      <LandThumbnail
        field={row}
        resourceId={row.id}
        skin={LandThumbnailSkin.SMALL}
        height={thumbnailSize}
        width={thumbnailSize}
      />
      <Stack>
        <Typography variant="body2">{row.name}</Typography>
        {!hideAcreage && <Typography variant="caption">{acres(row.acres)}</Typography>}
        {!hideLocation && (
          <Typography variant="caption">
            {row.county ? `${row.county}, ` : ''}
            {row.state ? row.state.toUpperCase() : ''}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export const fieldNameColumn = ({
  t,
  cellProps,
  overrides,
}: {
  t: TFunction
  cellProps?: CellProps
  overrides?: Partial<Omit<GridColDef<FieldModel>, 'renderCell' | 'field'>>
}) =>
  ({
    field: 'name',
    headerName: t('field', { ns: '@cibo/landmanager/FieldColumns' }),
    hideable: false,
    flex: 1,
    minWidth: 220,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<FieldModel, string>) => (
      <FieldNameCell {...params} {...cellProps} />
    ),
    ...overrides,
  } as GridColDef<FieldModel>)
