import { BenchmarkStatusReason, FieldModel, TaskStatusReason } from '@cibo/core'
import { FieldNameCell } from '@cibo/landmanager'
import { AuthUserPermission, Can } from '@cibo/profile'
import {
  DataGridPro,
  LAND_MANAGER_ROUTES,
  Markdown,
  ResponsiveDialog,
  useReasonMessages,
} from '@cibo/ui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Stack,
  Typography,
} from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId } from '@mui/x-data-grid-pro'
import { flatten, uniqWith } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useProgramEngagementContext } from '../../context'
import { useProgramEngagementFields, useUpdateProgramEngagement } from '../../queries'

type FieldReasons = {
  id: string
  field: FieldModel
  ineligibleReasons: TaskStatusReason<BenchmarkStatusReason>[]
  incompleteReasons: TaskStatusReason<BenchmarkStatusReason>[]
}

export const ProgramEngagementWarning = () => {
  const { t } = useTranslation('@cibo/programs/ProgramEngagementWarning')
  const [open, setOpen] = useState(false)
  const [hasIncomplete, setHasIncomplete] = useState(false)
  const [overage, setOverage] = useState({ acres: 0, fields: 0 })
  const [rows, setRows] = useState<Array<FieldReasons>>()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([])
  const { program, engagement } = useProgramEngagementContext()
  const updateEngagement = useUpdateProgramEngagement()
  //@ts-ignore id? is handled in the hook
  const fields = useProgramEngagementFields({ id: engagement.data?.id })
  const { byReason } = useReasonMessages()
  const columns = useMemo(
    () =>
      [
        {
          field: 'field',
          headerName: t('field', { ns: '@cibo/landmanager/FieldColumns' }),
          hideable: false,
          flex: 1,
          minWidth: 220,
          filterable: false,
          disableColumnMenu: true,
          renderCell: ({ value }: GridRenderCellParams<any, FieldModel, FieldReasons>) =>
            //@ts-ignore FieldNameCell doesn't actually need anything elses
            !!value ? <FieldNameCell row={value} /> : null,
        },
        {
          field: 'incompleteReasons',
          headerName: t('incomplete'),
          hideable: false,
          flex: 2,
          minWidth: 220,
          filterable: false,
          sortable: false,
          disableColumnMenu: true,

          // reasons are assumed to be field specific
          renderCell: ({
            value,
          }: GridRenderCellParams<
            any,
            TaskStatusReason<BenchmarkStatusReason>[],
            FieldReasons
          >) => {
            const reasons = uniqWith(
              (a, b) => a.traitId === b.traitId,
              flatten(value?.map(({ reasons }) => reasons) ?? [])
            )
            return (
              <ul style={{ listStyleType: 'disc', marginLeft: 8 }}>
                {reasons.map(reason => (
                  <li key={reason.traitId + reason.reason}>{byReason(reason)}</li>
                ))}
              </ul>
            )
          },
        },
        {
          field: 'ineligibleReasons',
          headerName: t('ineligible'),
          hideable: false,
          flex: 2,
          minWidth: 220,
          filterable: false,
          sortable: false,
          disableColumnMenu: true,

          // reasons are assumed to be field specific
          renderCell: ({
            value,
          }: GridRenderCellParams<
            any,
            TaskStatusReason<BenchmarkStatusReason>[],
            FieldReasons
          >) => {
            const reasons = uniqWith(
              (a, b) => a.reason === b.reason,
              flatten(value?.map(({ reasons }) => reasons) ?? [])
            )
            return (
              <ul style={{ listStyleType: 'disc', marginLeft: 8 }}>
                {reasons.map(reason => (
                  <li key={reason.traitId + reason.reason}>{byReason(reason)}</li>
                ))}
              </ul>
            )
          },
        },
      ] as GridColDef<FieldReasons>[],
    []
  )

  useEffect(() => {
    const acres = fields.data?.items.reduce((acc, f) => acc + f.acres, 0) || 0
    if (
      program.data &&
      engagement.data?.exceedsProgramGrowerLimits({ ledger: program.data.program.ledger, acres })
    ) {
      const overAcres = acres - (program.data.program.ledger.maxAcresPerGrower ?? Infinity)
      const overFields =
        engagement.data.fields.length - (program.data.program.ledger.maxFieldsPerGrower ?? Infinity)
      setOverage({
        acres: overAcres,
        fields: overFields,
      })
      setOpen(true)
    } else {
      setOpen(false)
    }
    const engagementStatus = engagement.data?.entranceTaskStatus()

    const rows =
      fields.data?.items
        .map(field => ({
          id: field.id,
          field,
          incompleteReasons:
            engagementStatus?.status.progress?.reasonItems.filter(({ id }) => id === field.id) ??
            [],
          ineligibleReasons:
            engagementStatus?.status.eligibility?.reasonItems.filter(({ id }) => id === field.id) ??
            [],
        }))
        .filter(row => row.incompleteReasons.length > 0 || row.ineligibleReasons.length > 0) || []
    setRows(rows)
    if (rows.some(({ incompleteReasons }) => incompleteReasons.length > 0)) {
      setHasIncomplete(true)
    }
  }, [engagement.dataUpdatedAt, program.isFetched, fields.dataUpdatedAt])

  const onRemoveSelected = () => {
    if (engagement.data?.id) {
      updateEngagement.mutate({
        action: 'remove',
        fields: rowSelectionModel as string[],
        id: engagement.data?.id,
      })
    }
  }

  const entranceStatus = engagement.data?.entranceTaskStatus()
  const errorTypes = []
  if (overage.acres > 0) {
    errorTypes.push(t('overAcres', { count: overage.acres }))
  }
  if (overage.fields > 0) {
    errorTypes.push(t('overFields', { count: overage.fields }))
  }
  if (!entranceStatus?.status.eligibility?.eligible) {
    errorTypes.push(
      t('ineligibleFields', { count: entranceStatus?.status.eligibility?.ineligibleCount })
    )
  }
  if (!entranceStatus?.status.progress?.completed) {
    errorTypes.push(
      t('incompleteFields', { count: entranceStatus?.status.progress?.reasonItems.length })
    )
  }

  return (
    <>
      <Fade in={!!errorTypes.length}>
        <Collapse in={!!errorTypes.length}>
          <Alert
            severity="error"
            action={
              <Button color="error" onClick={() => setOpen(true)} size="small" variant="contained">
                {t('more')}
              </Button>
            }
          >
            {t('warning')}
          </Alert>
        </Collapse>
      </Fade>
      <ResponsiveDialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>{t('title')}</DialogTitle>
        <DialogContent>
          <Box maxWidth="sm" mb={3}>
            <Markdown>
              {t('overviewMd', {
                reasons: errorTypes.join('  \n'),
                engageType: t('engageType', {
                  context: program.data?.program.engageType,
                  ns: '@cibo/ui',
                }).toLowerCase(),
              })}
            </Markdown>
          </Box>

          <DataGridPro
            columns={columns}
            rows={rows ?? []}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={rowSelectionModel => setRowSelectionModel(rowSelectionModel)}
            getRowHeight={() => 'auto'}
            checkboxSelection
            autoHeight
            hideCellFocus
          />

          <Collapse in={updateEngagement.isError}>
            <Alert severity="error">{t('updateError')}</Alert>
          </Collapse>

          <Can useAny={[AuthUserPermission.DEBUG_USER]}>
            <Accordion disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography color="error">Entrance Status</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack m={3} spacing={3}>
                  <pre>{JSON.stringify(engagement.data?.entranceTaskStatus(), null, 2)}</pre>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Can>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t('close')}</Button>
          {hasIncomplete && (
            <Button
              onClick={() => setOpen(false)}
              component={Link}
              to={LAND_MANAGER_ROUTES.PROFILE_FIELDS}
              state={{ profileFieldIds: rowSelectionModel }}
              variant="contained"
              disabled={!rowSelectionModel.length}
            >
              {t('profile')}
            </Button>
          )}
          <LoadingButton
            loading={updateEngagement.isPending}
            onClick={onRemoveSelected}
            variant="contained"
            disabled={
              rowSelectionModel.length === 0 ||
              rowSelectionModel.length === engagement.data?.fields.length
            }
          >
            {t('removeSelected', { count: rowSelectionModel.length })}
          </LoadingButton>
        </DialogActions>
      </ResponsiveDialog>
    </>
  )
}
