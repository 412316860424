import { RDEqipEligibilityFarmInfo } from '@cibo/core'
import { Markdown, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { FarmInfoTaskEditorTable } from './FarmInfoTaskEditorTable'

export const FarmInfoTaskEditor = ({
  ownerName,
  userRole,
  resourceIds,
  detailRequirements,
  onSuccess,
  onError,
  onUpdating,
}: ResourceDetailFeatureTaskEditorProps<RDEqipEligibilityFarmInfo>) => {
  const { t } = useTranslation('@cibo/landmanager/FarmInfo')

  return (
    <Stack spacing={3}>
      <Markdown
        overrides={{
          em: {
            component: ({ children }: { children: ReactNode }) => (
              <>
                <br />
                <Typography variant="body2" color="text.secondary" component="span">
                  {children}
                </Typography>
              </>
            ),
          },
        }}
      >
        {t('tableCta', { context: userRole, name: ownerName })}
      </Markdown>

      <FarmInfoTaskEditorTable
        {...{ resourceIds, detailRequirements, onSuccess, onError, onUpdating }}
      />
    </Stack>
  )
}
