import {
  FERTILIZER_METHODS,
  FERTILIZER_TYPE,
  FERTILIZER_TYPE_LB_PER_ACRE,
  FERTILIZER_TYPE_RATE_UNIT,
} from '@cibo/core'
import dayjs from 'dayjs'
import { filter, sum } from 'ramda'
import * as Yup from 'yup'
import { ValidationSchemaFunctionArguments } from '../types'
import { isDetailRequired } from '../useIsRequired'

const constituentValidation = ({
  fieldName,
  requirement,
  t,
}: ValidationSchemaFunctionArguments & { fieldName: string }) => {
  const stringSchema = Yup.string()
  const numberSchema = Yup.number()
    .min(0, t('min_percent'))
    .max(100, t('max_percent'))
    .typeError(t('required_percent'))

  return Yup.lazy(value =>
    isDetailRequired({ requirement, fieldName })
      ? value === ''
        ? stringSchema.required(t('required', { context: value }))
        : numberSchema.required(t('required', { context: value }))
      : value === ''
      ? stringSchema.nullable()
      : numberSchema.nullable()
  )
}

export const FertilizerEventValidationSchema = ({
  t,
  requirement,
}: ValidationSchemaFunctionArguments) =>
  Yup.object().shape({
    date: Yup.date()
      .required(t('required_date'))
      .test(
        'dateRequired',
        t('required_fourDigitYear'),
        // @ts-ignore typing doesn't like this.
        (date: string) => dayjs(date).year() > 1969
      )
      .typeError(t('required_date')),
    type: Yup.string().oneOf(FERTILIZER_TYPE, t('oneOf_type')).required(t('required_type')),
    units: Yup.mixed().test('unitsExpected', t('required_unit'), function () {
      const { product, nitrogenAmnt, rate, units } = this.parent
      // if there are no other nitrogen amount values defined, unit is not expected to be defined
      if (nitrogenAmnt === undefined && product === undefined && rate === undefined) {
        return true
      }
      // if product or nitrogen amount is defined, unit is not expected to be defined
      if (nitrogenAmnt !== undefined || product !== undefined) {
        return true
      }
      if (rate > 0 && units !== undefined) {
        return true
      }
      return false
    }),
    // @ts-ignore
    rate: Yup.number().test('rate', t('required_rate'), function () {
      const { type, nitrogenAmnt, rate, unit, product } = this.parent
      let isValid: boolean
      // if there are no other nitrogen amount values defined, rate is not expected to be defined
      if (nitrogenAmnt === undefined && product === undefined && unit === undefined) {
        isValid = true
      }
      // if product or nitrogen amount is defined, rate is not expected to be defined
      if (nitrogenAmnt !== undefined || product !== undefined) {
        isValid = true
      }
      if (FERTILIZER_TYPE_RATE_UNIT.includes(type)) {
        isValid =
          (rate !== undefined && rate > 0) || (nitrogenAmnt !== undefined && nitrogenAmnt > 0)
      } else {
        isValid = nitrogenAmnt !== undefined && nitrogenAmnt > 0
      }
      return !isValid
        ? Yup.mixed().test('unitsExpected', t('required_unit'), function () {
            const { product, nitrogenAmnt, rate } = this.parent
            // if there are no other nitrogen amount values defined, unit is not expected to be defined
            if (nitrogenAmnt === undefined && product === undefined && rate === undefined) {
              return false
            }
            // if product or nitrogen amount is defined, unit is not expected to be defined
            if (nitrogenAmnt !== undefined || product !== undefined) {
              return false
            }
            return true
          })
        : true
    }),
    nitrogenAmnt: Yup.number()
      .min(0)
      .max(400)
      .test('nitrogenAmnt', t('required_nitrogenAmnt'), function () {
        const { type, nitrogenAmnt, rate, units } = this.parent
        if (FERTILIZER_TYPE_RATE_UNIT.includes(type)) {
          return (!!rate && !!units) || (nitrogenAmnt !== undefined && nitrogenAmnt > 0)
        } else {
          return nitrogenAmnt !== undefined && nitrogenAmnt > 0
        }
      }),
    method: Yup.string()
      .oneOf(FERTILIZER_METHODS, t('oneOf_method'))
      [isDetailRequired({ requirement, fieldName: 'method' }) ? 'required' : 'nullable'](
        t('required_method')
      ),
    slowRelease: Yup.boolean()
      .typeError(t('required_slowRelease'))
      [isDetailRequired({ requirement, fieldName: 'slowRelease' }) ? 'required' : 'nullable'](
        t('required_slowRelease')
      ),
    inhibitor: Yup.boolean()
      .typeError(t('required_inhibitor'))
      [isDetailRequired({ requirement, fieldName: 'inhibitor' }) ? 'required' : 'nullable'](
        t('required_inhibitor')
      ),
    ammonium: Yup.boolean()
      .typeError(t('required_ammonium'))
      [isDetailRequired({ requirement, fieldName: 'ammonium' }) ? 'required' : 'nullable'](
        t('required_ammonium')
      ),
    nitrogenPer: constituentValidation({ t, requirement, fieldName: 'nitrogenPer' }),
    phosphorusPer: constituentValidation({ t, requirement, fieldName: 'phosphorusPer' }),
    potassiumPer: constituentValidation({ t, requirement, fieldName: 'potassiumPer' }),
    sulfurPer: constituentValidation({ t, requirement, fieldName: 'sulfurPer' }),
    combinedPercent: Yup.mixed().test('componentMax', t('max_combined'), function () {
      const { sulfurPer, potassiumPer, phosphorusPer, nitrogenPer } = this.parent
      return sum(filter(a => !!a, [sulfurPer, potassiumPer, phosphorusPer, nitrogenPer])) <= 100
    }),
    product: Yup.string().when('type', {
      is: [...FERTILIZER_TYPE_LB_PER_ACRE, 'other'],
      then: Yup.string().required(t('required_product')),
      otherwise: Yup.string()[
        isDetailRequired({ requirement, fieldName: 'product' }) ? 'required' : 'nullable'
      ](t('required_product')),
    }),
  })
