import { Config, getMpNodeAgent } from '@cibo/core'

function getCookie(cname: string) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const fetchSelectedPracticesPDF = async ({ engagementId }: { engagementId: string }) => {
  const agent = getMpNodeAgent()
  const PDF_PATH = `${Config.get(
    'NODE_SERVICE_URL'
  )}selected-practices/v3/pdf?engagementId=${engagementId}`

  const CbEnvExpressAccessToken = getCookie('CbEnvExpressAccessToken') // required in dev
  const CbAuthCookie = getCookie('CbAuthCookie')

  return agent.get(PDF_PATH, {
    responseType: 'blob',
    headers: { CbEnvExpressAccessToken, CbAuthCookie },
  })
}
