import { DataGridPro, Markdown, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { useSaveIndicator } from '@cibo/ui/src/components/SaveIndicator/SaveIndicatorProvider'
import { MenuItem, Select, Stack, Typography } from '@mui/material'
import { GridRenderCellParams, GridRowHeightReturnValue, useGridApiRef } from '@mui/x-data-grid-pro'
import { ReactNode, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldColumns } from '../../../components/FieldColumns'
import { useFields, useUpdateMultipleFields } from '../../../queries'
import { RDEarlyAdopterTillage } from './types'

const TRAIT_ID = 'earlyAdopterTillage'

// This detail is not yearly, but a single value for the field.
// I am assuming it's okay to use the current year and interpret the
// "more than"/"less than" answers later according to when the detail was created.
const YEAR = new Date().getFullYear()

export const EarlyAdopterTillageTaskEditor = ({
  ownerName,
  userRole,
  resourceIds,
  onSuccess,
}: ResourceDetailFeatureTaskEditorProps<RDEarlyAdopterTillage>) => {
  const { data: fieldModels, isLoading, isFetched } = useFields(resourceIds)
  const { t } = useTranslation('@cibo/landmanager/EarlyAdopterTillage')
  const updateFields = useUpdateMultipleFields()
  const gridApi = useGridApiRef()

  const { setIsSaving, setIsDirty, setIsError } = useSaveIndicator()

  const earliest = YEAR - 20
  const latest = YEAR - 8

  const options = useMemo(() => {
    const options = [
      { value: 'remove', label: t('remove') },
      { value: 'moreThanTwentyYearsAgo', label: t('moreThanTwentyYearsAgo', { earliest }) },
    ]

    for (let year = earliest + 1; year < latest; year++) {
      options.push({ value: `${year}`, label: `${year}` })
    }

    options.push({ value: 'lessThanEightYearsAgo', label: t('lessThanEightYearsAgo', { latest }) })

    return options
  }, [])

  const commitEditedValue = (resourceId: string, value: string) => {
    const detailInput =
      value === 'remove'
        ? undefined
        : value === 'moreThanTwentyYearsAgo'
        ? { moreThanTwentyYearsAgo: true }
        : value === 'lessThanEightYearsAgo'
        ? { lessThanEightYearsAgo: true }
        : { cropYear: parseInt(value) }

    setIsError(false)
    setIsDirty(true)
    setIsSaving(true)
    updateFields
      .mutateAsync([{ resourceId, details: [{ traitId: TRAIT_ID, input: detailInput }] }])
      .then(() => onSuccess?.())
      .catch(() => setIsError(true))
      .finally(() => setIsSaving(false))
  }

  // Flatten initial rows to make datagrid edits work.
  // DataGrid edits flatten the row object, which breaks getters on a FieldModel.
  const rows = useMemo(
    () =>
      fieldModels?.map(field => {
        const detail = field.resolveStandingDetail(TRAIT_ID)

        return {
          id: field.resourceId,
          geometrySlug: field.geometrySlug,
          state: field.state,
          name: field.name,
          county: field.county,
          acres: field.acres,
          answer: {
            answer:
              (detail?.input?.moreThanTwentyYearsAgo
                ? 'moreThanTwentyYearsAgo'
                : detail?.input?.lessThanEightYearsAgo
                ? 'lessThanEightYearsAgo'
                : detail?.input?.cropYear) || '',
            immutable: detail?.immutable,
          },
        }
      }),
    [isFetched]
  )

  const handleChangeAnswer = useCallback((params: GridRenderCellParams, value: string) => {
    gridApi.current?.updateRows([
      { id: params.id, answer: { answer: value === 'remove' ? '' : value } },
    ])

    commitEditedValue(`${params.id}`, value)
  }, [])

  const { fieldNameColumn } = useFieldColumns()

  const columns = useMemo(
    () => [
      fieldNameColumn,
      {
        field: 'answer',
        headerName: t('columnTitle'),
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Select
            onChange={event => handleChangeAnswer(params, event.target.value)}
            value={params.value.answer}
            fullWidth
            disabled={params.value.immutable}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        ),
      },
    ],
    []
  )

  const getRowHeight = useCallback((): GridRowHeightReturnValue => 'auto', [])

  return (
    <Stack spacing={3}>
      <Markdown
        overrides={{
          em: {
            component: ({ children }: { children: ReactNode }) => (
              <>
                <br />
                <Typography variant="body2" color="text.secondary" component="span">
                  {children}
                </Typography>
              </>
            ),
          },
        }}
      >
        {t('tableCta', { context: userRole, name: ownerName })}
      </Markdown>

      <DataGridPro
        apiRef={gridApi}
        disableRowSelectionOnClick
        rows={rows || []}
        getRowHeight={getRowHeight}
        columns={columns}
        loading={isLoading || updateFields.isPending}
        autoHeight
      />
    </Stack>
  )
}
