import { DataGridPro } from '@cibo/ui'
import { styled } from '@mui/material'

export const RollupRowDataGridPro = styled(DataGridPro)(() => ({
  '.MuiDataGrid-cell': {
    padding: 0,
    position: 'relative',
  },
  '.MuiSelect': { boxShadow: 'none' },
  '.MuiDataGrid-pinnedRows': {
    backgroundColor: 'unset',
    boxShadow: 'unset',
  },
}))
