import {
  FieldModel,
  ProgramEngagementModel,
  ProgramModel,
  ProgramTaskRole,
  UserId,
} from '@cibo/core'
import { QUERY_KEY, useFields } from '@cibo/landmanager'
import { useAuth } from '@cibo/profile'
import { UseQueryResult, useQueryClient } from '@tanstack/react-query'
import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PROGRAMS_QUERY_KEY, useProgramEngagement, useProgramModel } from '../../queries'

type ProgramEngagementState = {
  basePath: string
  program: UseQueryResult<ProgramModel | null, unknown>
  ownerName: string

  engagement: UseQueryResult<ProgramEngagementModel | null, unknown>
  // @deprecated - prefer using `engagement.refetch()`
  refreshEngagement(): void

  engagementFields: UseQueryResult<FieldModel[] | undefined, Error>
  // @deprecated - prefer using `engagementFields` for state & refetch access
  fields?: FieldModel[]

  continueFunction: Function | undefined
  setContinueFunction(f: Function | undefined): void

  userRole: ProgramTaskRole
  userId: UserId
}

const DEFAULT_VALUES = {
  basePath: '',
  ownerName: '',
  engagement: {} as UseQueryResult<ProgramEngagementModel | null, unknown>,
  userRole: 'participant',
  program: {} as UseQueryResult<ProgramModel | null, unknown>,
  continueFunction: undefined,
  setContinueFunction: () => console.log('default setContinueFunction()'),
  refreshEngagement: () => console.log('default refreshEngagement()'),
  refreshFields: () => console.log('default refreshEngagement()'),
  userId: 0,
  engagementFields: {} as UseQueryResult<FieldModel[] | undefined, Error>,
} as ProgramEngagementState

export const ProgramEngagementContext = createContext<ProgramEngagementState>(DEFAULT_VALUES)
ProgramEngagementContext.displayName = 'ProgramEngagementContext'

type ProgramEngagementProviderProps = {
  basePath: string
  children: React.ReactNode
  engagementId?: string
  programId: string
  userId: UserId
}

export const ProgramEngagementProvider = ({
  basePath,
  children,
  engagementId,
  programId,
  userId,
}: ProgramEngagementProviderProps) => {
  const { t } = useTranslation('@cibo/programs/ProgramEngagementProvider')
  const engagement = useProgramEngagement({ id: engagementId })
  const program = useProgramModel({ programId: engagement.data?.programId ?? programId })
  const engagementFields = useFields(engagement.data?.fields ?? [])
  const { userId: selfId } = useAuth()
  const userRole = selfId === engagement.data?.ownedBy?.userId ? 'participant' : 'manager'
  const ownerName = engagementFields.data?.[0]?.ownerLabel ?? (t('applicant') as string)

  const queryClient = useQueryClient()

  const refreshEngagement = () => {
    queryClient.invalidateQueries({
      queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, engagementId],
    })
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.FIELDSET, engagement.data?.fields?.filter(Boolean).join(',')],
    })
  }

  const [continueFunction, setContinueFunction] = useState<Function | undefined>()

  return (
    <ProgramEngagementContext.Provider
      value={{
        basePath,
        program,
        refreshEngagement,
        ownerName,
        engagement,
        userRole,
        userId,
        fields: engagementFields.data,
        engagementFields,
        continueFunction,
        setContinueFunction: (f: Function | undefined) => {
          // useState interprets a function as an updater,
          // but we want the new state to be the function
          setContinueFunction(() => f)
        },
      }}
    >
      {children}
    </ProgramEngagementContext.Provider>
  )
}

export const useProgramEngagementContext = () => useContext(ProgramEngagementContext)
