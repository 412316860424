import { DetailRequirement } from '@cibo/core'
import {
  RollupQuestionProvider,
  useDetailsChanged,
  useFields,
  useRollupQuestion,
} from '@cibo/landmanager'
import { ResourceDetailFeatureTaskEditorProps, useSaveIndicator } from '@cibo/ui'
import { pluck, uniq } from 'ramda'
import { useEffect } from 'react'
import { TraitTable, TraitTableProps } from '../../../components/TraitTable'
import { useProgramEngagementContext } from '../../../context'

const TraitTableImpl = (props: TraitTableProps) => {
  const { rollupRequested, commitChanges } = useRollupQuestion()
  const { setContinueFunction } = useProgramEngagementContext()
  const { setIsSaving, setIsDirty, setIsError } = useSaveIndicator()

  useEffect(() => {
    setContinueFunction(
      rollupRequested
        ? (args: any) => {
            setIsError(false)
            setIsDirty(true)
            setIsSaving(true)
            commitChanges().then(args => {
              setIsSaving(false)
              return args
            })
          }
        : undefined
    )
  }, [rollupRequested])

  return <TraitTable {...props} />
}

export const TraitTableTaskEditor = ({
  detailRequirements,
  resourceIds,
  onUpdating,
  onError,
  onSuccess,
  ownerName,
  engagement,
  task,
}: ResourceDetailFeatureTaskEditorProps<any>) => {
  const fields = useFields(resourceIds)

  useDetailsChanged(() => onSuccess && onSuccess())

  const focusTraitId = detailRequirements[0].traitId
  const years = uniq(pluck('year', detailRequirements))

  return (
    <RollupQuestionProvider
      traitId={focusTraitId}
      fieldModels={fields.data || []}
      years={years as number[]}
    >
      <TraitTableImpl
        programIds={[engagement.programId]}
        detailRequirements={detailRequirements as DetailRequirement[]}
        fieldModels={fields.data || []}
        traitId={focusTraitId}
        loading={fields.isPending}
        growerName={ownerName}
        task={task}
      />
    </RollupQuestionProvider>
  )
}
