import { DetailRequirement, FieldModel, Integer, ResourceDetail } from '@cibo/core'
import { DataGridPro, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Box, MenuItem, Select, Stack, styled, Typography } from '@mui/material'
import { GridApi, GridRenderCellParams, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fieldColumnAttributes,
  FieldNameCell,
} from '../../../components/FieldColumns/fieldNameColumn'
import { useFields } from '../../../queries'

const StyledDataGridPro = styled(DataGridPro)(() => ({
  '.MuiDataGrid-cell': {
    padding: 0,
    position: 'relative',
  },
  '.MuiSelect': { boxShadow: 'none' },
  '.MuiDataGrid-pinnedRows': {
    backgroundColor: 'unset',
    boxShadow: 'unset',
  },
}))

export type EditorTableDetail = { resourceId: string; detail?: ResourceDetail }
export type EditorTableDetailRemoval = { resourceId: string; requirement: DetailRequirement }

export type EditorTableChanges = {
  update?: EditorTableDetail[]
  remove?: EditorTableDetailRemoval[]
}

export type FieldDetailEditorTableProps<
  RDT extends ResourceDetail = ResourceDetail<any, any>,
  O extends readonly string[] = readonly string[]
> = {
  detailKey: keyof RDT['input'] | keyof RDT['result'] | 'result'
  ns: string
  options: O
  programId?: string
  onDetailsModelChanged(model: EditorTableDetail[], changes: EditorTableChanges): void
} & ResourceDetailFeatureTaskEditorProps<RDT>

export const FieldDetailEditorTable = <RDT extends ResourceDetail, O extends readonly string[]>({
  options,
  detailRequirements: [firstRequirement],
  ns,
  detailKey,
  programId,
  resourceIds,
  onDetailsModelChanged,
}: FieldDetailEditorTableProps<RDT, O>) => {
  const { t } = useTranslation('@cibo/landmanager/FieldDetailEditorTable')
  const traitId = firstRequirement.traitId
  const year = firstRequirement.year as Integer
  const detailKeyIsResult = detailKey === 'result'
  const fieldModels = useFields(resourceIds)
  const gridApi = useGridApiRef()
  const [bulkAnswer, setBulkAnswer] = useState<string>()

  const answerForDetail = (detail: ResourceDetail) =>
    detailKeyIsResult ? detail?.result : detail?.result[detailKey]

  const detailForAnswer = (answer: string) =>
    detailKeyIsResult
      ? { traitId, programId, year, result: answer }
      : { traitId, programId, year, result: { [detailKey]: answer } }

  const getModels = useCallback((gridApi: GridApi) => {
    const rowData = gridApi.getSortedRows()

    const models: EditorTableDetail[] = []

    rowData.forEach(row => {
      models.push({ resourceId: row.id, detail: detailForAnswer(row.answer) })
    })

    return models
  }, [])

  const changeBulkAnswer = useCallback(
    (answer: string) => {
      const rowData = gridApi.current.getSortedRows()

      if (answer === 'remove') {
        setBulkAnswer('')
        const fieldsWithDetail: string[] = []

        rowData.forEach(row => {
          if (row.answer !== '') {
            fieldsWithDetail.push(row.id)
            gridApi.current?.updateRows([{ id: row.id, answer: '' }])
          }
        })

        onDetailsModelChanged(getModels(gridApi.current), {
          remove: fieldsWithDetail.map(resourceId => ({
            resourceId,
            requirement: { traitId, year, programId, dataType: 'field' },
          })),
        })
      } else {
        setBulkAnswer(answer)
        const fieldsToUpdate: string[] = []

        rowData.forEach(row => {
          if (row.answer !== answer) {
            gridApi.current?.updateRows([{ id: row.id, answer }])
            fieldsToUpdate.push(row.id)
          }
        })

        const newDetail = detailForAnswer(answer)

        onDetailsModelChanged(getModels(gridApi.current), {
          update: fieldsToUpdate.map(resourceId => ({
            resourceId,
            detail: newDetail,
          })),
        })
      }
    },
    [gridApi.current, setBulkAnswer, getModels, onDetailsModelChanged]
  )

  const changeFieldAnswer = useCallback(
    (id: GridRowId, answer: string) => {
      if (answer === 'remove') {
        gridApi.current?.updateRows([{ id, answer: '' }])

        onDetailsModelChanged(getModels(gridApi.current), {
          remove: [
            {
              resourceId: id as string,
              requirement: { traitId, year, programId, dataType: 'field' },
            },
          ],
        })

        setBulkAnswer('')
      } else {
        gridApi.current?.updateRows([{ id, answer }])

        onDetailsModelChanged(getModels(gridApi.current), {
          update: [
            {
              resourceId: id as string,
              detail: detailForAnswer(answer),
            },
          ],
        })

        const rowData = gridApi.current.getSortedRows()

        if (rowData.every(row => row.answer === answer)) {
          setBulkAnswer(answer)
        } else {
          setBulkAnswer('')
        }
      }
    },
    [gridApi.current, setBulkAnswer, getModels]
  )

  const handleChangeAnswer = useCallback(
    (params: GridRenderCellParams, answer: typeof options[number] | 'remove') => {
      if (params.id === 'bulkAnswerRow') {
        changeBulkAnswer(answer)
      } else {
        changeFieldAnswer(params.id, answer)
      }
    },
    [changeBulkAnswer, changeFieldAnswer]
  )

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: t('field'),
        hideable: false,
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<FieldModel>) => (
          <Box sx={{ marginLeft: 2 }}>
            {params.row.id === 'bulkAnswerRow' ? (
              <Typography sx={{ fontWeight: 'bold', my: 2 }}>{t('answerForAllFields')}</Typography>
            ) : (
              <FieldNameCell {...params} />
            )}
          </Box>
        ),
      },
      {
        field: 'answer',
        headerName: t('answer', { ns, defaultValue: null }) ?? t('answer'),
        flex: 1,
        hideable: false,
        disableColumnMenu: true,
        filterable: false,
        renderCell: (
          params: GridRenderCellParams<
            FieldModel & { answer: any; immutable: boolean; source?: string }
          >
        ) => {
          const { row } = params
          return (
            <Stack spacing={1} direction="row">
              <Select
                value={row.answer}
                onChange={event => handleChangeAnswer(params, event.target.value)}
                placeholder={t('select')}
                data-testid={`selectDropdown_${row.id}`}
                variant="outlined"
                sx={{ width: '100%' }}
                disabled={row.immutable}
              >
                {row.id === 'bulkAnswerRow' ? (
                  <MenuItem value="remove" data-testid="removeAll">
                    <Typography variant="caption">{t('removeAllAnswers')}</Typography>
                  </MenuItem>
                ) : (
                  row.answer !== '' && (
                    <MenuItem value="remove" data-testid="remove">
                      <Typography variant="caption">{t('remove')}</Typography>
                    </MenuItem>
                  )
                )}

                {options.map(option => (
                  <MenuItem key={option} value={option} data-testid={`option::${option}`}>
                    {t(`option_${option}`, { ns })}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          )
        },
      },
    ]
  }, [handleChangeAnswer, fieldModels.dataUpdatedAt, options])

  const rows = useMemo(() => {
    const answers = new Set()

    const models: EditorTableDetail[] = []

    const rows = !fieldModels.data
      ? []
      : fieldModels.data.map(field => {
          const detail = field.findDetail({ traitId, year, programId })

          models.push({ resourceId: field.resourceId, detail })

          const answer = (detail && answerForDetail(detail)) || ''
          answers.add(answer)

          return {
            ...fieldColumnAttributes(field),
            answer,
            immutable: detail?.immutable,
            source: detail?.source,
          }
        })

    onDetailsModelChanged(models, {})

    if (answers.size === 1) {
      setBulkAnswer(rows[0].answer)
    }

    return rows
  }, [fieldModels.dataUpdatedAt, setBulkAnswer, onDetailsModelChanged])

  const isAnyDetailImmutable = !!fieldModels.data?.some(
    field => field.findDetail({ traitId, year, programId })?.immutable
  )

  return (
    <StyledDataGridPro
      apiRef={gridApi}
      autoHeight
      columns={columns}
      disableRowSelectionOnClick
      getRowHeight={() => 'auto'}
      hideCellFocus
      pinnedRows={
        fieldModels.data?.length && fieldModels.data.length > 1
          ? {
              top: [
                {
                  id: 'bulkAnswerRow',
                  answer: bulkAnswer || '',
                  immutable: isAnyDetailImmutable,
                },
              ],
            }
          : {}
      }
      rows={rows}
    />
  )
}
