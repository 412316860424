import { RDEqipEligibilityFarmInfo } from '@cibo/core'
import {
  fieldColumnAttributes,
  LandThumbnail,
  LandThumbnailSkin,
  useAssignFieldsToFSAFarm,
  useUnassignFieldsToFSAFarm,
  useUpdateMultipleFields,
} from '@cibo/landmanager'
import { DataGridPro, Markdown, ResourceDetailFeatureTaskEditorProps, useFigures } from '@cibo/ui'
import { Stack, Typography } from '@mui/material'
import { GridRenderCellParams, GridRowHeightReturnValue, useGridApiRef } from '@mui/x-data-grid-pro'
import { any, prop } from 'ramda'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ResourceConcernsCell } from '../../features/ResourceDetailFeatures/EQIP/RDEqipReviewRecommendationResultsResourceDetailFeature/ResourceConcernsCell'
import { useEqipEngagementFieldsWithConcerns } from '../../queries/useEqipRecommendations'
import { PracticesSelectedCell } from '../PracticeSelectionConfirmation'

type FarmInfoRow = ReturnType<typeof fieldColumnAttributes> & {
  id: string
  farmNumber: string
  tractNumber: string
}

type SelectedPracticesTableProps = Pick<
  ResourceDetailFeatureTaskEditorProps<RDEqipEligibilityFarmInfo>,
  'resourceIds' | 'detailRequirements' | 'onSuccess' | 'onError' | 'onUpdating' | 'engagementId'
>

export const SelectedPracticesTable = ({
  engagementId,
  detailRequirements,
}: SelectedPracticesTableProps) => {
  const { t } = useTranslation('@cibo/programs/EqipSelectedPracticesPDF')
  const { traitId, year } = detailRequirements[0]
  const gridApi = useGridApiRef()

  const fieldModels = useEqipEngagementFieldsWithConcerns(engagementId)
  const updateFields = useUpdateMultipleFields()
  const assignFields = useAssignFieldsToFSAFarm()
  const unassignFields = useUnassignFieldsToFSAFarm()

  const busy = any(prop('isPending'), [fieldModels, updateFields, assignFields, unassignFields])

  const { acres } = useFigures()

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: t('field', { ns: '@cibo/landmanager/FieldColumns' }),
        hideable: false,
        flex: 1,
        minWidth: 220,
        filterable: false,
        disableColumnMenu: true,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <Stack>
              <Stack direction="row" spacing={1.5} paddingY={0.75} alignItems={'center'}>
                <LandThumbnail
                  field={row}
                  resourceId={row.id}
                  skin={LandThumbnailSkin.SMALL}
                  height={68}
                  width={68}
                />
                <Stack>
                  <Typography variant="body2">{row.name}</Typography>
                  <Typography variant="caption">{acres(row.acres)}</Typography>
                  <Markdown typographyProps={{ variant: 'caption' }}>
                    {t('farm', { farmNumber: row.farmNumber })}
                  </Markdown>
                  <Markdown typographyProps={{ variant: 'caption' }}>
                    {t('tract', { tractNumber: row.tractNumber })}
                  </Markdown>
                </Stack>
              </Stack>
            </Stack>
          )
        },
      },

      {
        field: 'resourceConcerns',
        headerName: t('resourceConcern', { ns: '@cibo/programs/EQIP' }),
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ResourceConcernsCell,
      },
      {
        field: 'selectedPractices',
        headerName: t('practicesSelected', { ns: '@cibo/programs/EQIP' }),
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: PracticesSelectedCell,
      },
    ]
  }, [])

  const rows = useMemo(
    () =>
      fieldModels.data?.map(field => {
        const detail = field.resolveStandingDetail(traitId, year)

        return {
          ...fieldColumnAttributes(field),
          ...detail?.result,
          ...field,
        }
      }),
    [fieldModels.isFetched]
  )

  const getRowHeight = useCallback((): GridRowHeightReturnValue => 'auto', [])

  return (
    <DataGridPro<FarmInfoRow>
      apiRef={gridApi}
      autoHeight
      columns={columns}
      disableColumnSelector
      disableRowSelectionOnClick
      getRowHeight={getRowHeight}
      loading={busy}
      rows={rows || []}
    />
  )
}
