import { Button, ButtonGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const BULK_ANSWERS = ['yes', 'no', 'unknown'] as const
export type BulkAnswer = typeof BULK_ANSWERS[number]

export type BooleanRollupInputProps = {
  answer?: BulkAnswer
  answerValues: Partial<Record<BulkAnswer, { value: any }>>
  buttonDisabled: Partial<Record<BulkAnswer, boolean>>
  disabled?: boolean
  onChange(newAnswer: BulkAnswer): () => void
}

export const BooleanRollupInput = ({
  answer,
  answerValues,
  buttonDisabled,
  disabled,
  onChange,
}: BooleanRollupInputProps) => {
  const { t } = useTranslation('@cibo/landmanager/BooleanRollup')

  return (
    <ButtonGroup color="secondary" disabled={disabled}>
      {answerValues['yes'] && (
        <Button
          data-testid="boolean-rollup-yes"
          onClick={() => answer !== 'yes' && onChange('yes')()}
          variant={answer === 'yes' ? 'contained' : 'outlined'}
          disableElevation
          disabled={buttonDisabled['yes']}
        >
          {t('yes')}
        </Button>
      )}
      {answerValues['no'] && (
        <Button
          data-testid="boolean-rollup-no"
          onClick={() => answer !== 'no' && onChange('no')()}
          variant={answer === 'no' ? 'contained' : 'outlined'}
          disableElevation
          disabled={buttonDisabled['no']}
        >
          {t('no')}
        </Button>
      )}
      {answerValues['unknown'] && (
        <Button
          data-testid="boolean-rollup-unknown"
          onClick={() => answer !== 'unknown' && onChange('unknown')()}
          variant={answer === 'unknown' ? 'contained' : 'outlined'}
          disableElevation
          disabled={buttonDisabled['unknown']}
        >
          {t('unknown')}
        </Button>
      )}
    </ButtonGroup>
  )
}
