import { BenchmarkStateTraitReason, BenchmarkStatusReason, TraitId } from '@cibo/core'
import { collectBy } from 'ramda'
import { useTranslation } from 'react-i18next'

export const useReasonMessages = () => {
  const { t } = useTranslation('@cibo/ui/useReasonMessages')

  const byReason = ({ reason, traitId, year, reasonText }: BenchmarkStatusReason) =>
    byReasonCode({ reasonCode: reason, traitId, reasonText, years: year?.toString() })

  const byReasonCode = ({
    reasonCode,
    traitId,
    count,
    years,
    reasonText,
  }: {
    reasonCode: BenchmarkStateTraitReason
    traitId: TraitId
    count?: number
    years?: string
    reasonText?: string
  }) => {
    const tArguments = {
      context: traitId,
      traitName: t(`${traitId}`, { ns: 'traits' }),
      count,
      years,
    }
    return reasonText
      ? t(reasonText as string, tArguments)
      : t(`reasonCode_${reasonCode}`, tArguments)
  }

  const groupByCode = (reasons: BenchmarkStatusReason[]) => {
    const reasonGroups: BenchmarkStatusReason[][] = collectBy(
      (a: BenchmarkStatusReason) => `${a.reason}${a.traitId}${a.reasonText}`
    )(reasons)
    return reasonGroups.map(group => {
      const years = group.map(a => a.year).sort()
      const yearString =
        years.length > 2
          ? `${years.shift()}-${years.pop()}`
          : years.length === 2
          ? years.join(' & ')
          : years.join(', ')
      return byReasonCode({
        reasonCode: group[0].reason,
        traitId: group[0].traitId,
        years: yearString,
        count: years.length,
        reasonText: group[0].extraData?.capInfo || group[0].reasonText,
      })
    })
  }

  return {
    byReasonCode,
    groupByCode,
    byReason,
  }
}
