import { PropsWithChildren } from 'react'

type PDFPageProps = PropsWithChildren<{}>

export const PDFPage = ({ children }: PDFPageProps) => (
  <div
    style={{
      width: '8.26in',
      height: '11.69in',
      padding: '1in',
    }}
  >
    {children}
  </div>
)
