import { TillageImplement } from '@cibo/core'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import { ValidationSchemaFunctionArguments } from '../types'
import { isDetailRequired } from '../useIsRequired'

export const TillageEventValidationSchema = ({
  t,
  requirement,
}: ValidationSchemaFunctionArguments) =>
  Yup.object()
    .shape({
      date: Yup.date()
        .required(t('required', { context: 'date' }))
        .test(
          'dateRequired',
          t('required_fourDigitYear'),
          // @ts-ignore typing doesn't like this.
          (date: string) => dayjs(date).year() > 1969
        )
        .typeError(t('required', { context: 'date' })),
      implement: Yup.string<TillageImplement>()
        .required(t('required', { context: 'implement' }))
        .typeError(t('required', { context: 'implement' })),
      depth: Yup.number()
        .min(0, t('min', { context: 'depth' }))
        [isDetailRequired({ requirement, fieldName: 'depth' }) ? 'required' : 'optional'](
          t('required', { context: 'depth' })
        ),
      rowSpacing: Yup.number().when('implement', {
        is: 'stripTill',
        then: Yup.number()
          .min(0, t('min', { context: 'rowSpacing' }))
          .required(t('required', { context: 'rowSpacing' }))
          .typeError(t('required', { context: 'rowSpacing' })),
      }),
      stripWidth: Yup.number().when('implement', {
        is: 'stripTill',
        then: Yup.number()
          .min(0, t('min', { context: 'stripWidth' }))
          .max(99, t('max', { context: 'stripWidth' }))
          .required(t('required', { context: 'stripWidth' }))
          .typeError(t('required', { context: 'stripWidth' })),
      }),
      residueRemaining: Yup.number()
        .min(0, t('min', { context: 'residueRemaining' }))
        .max(1, t('max', { context: 'residueRemaining' }))
        .required(t('required', { context: 'residueRemaining' }))
        .typeError(t('required', { context: 'residueRemaining' })),
    })
    .required()
