import {
  CASH_CROP_HARVEST_TYPES,
  CASH_CROP_SPECIES,
  RDCashCrop,
  RDCashCropSpecies,
} from '@cibo/core'
import dayjs from 'dayjs'
import {
  DateSchema,
  ObjectSchema,
  StringSchema,
  boolean,
  date,
  mixed,
  number,
  object,
  ref,
  string,
} from 'yup'
import { DetailEditorProps, ITraitFeatureByIdYear } from '../types'
import { isDetailRequired } from '../useIsRequired'
import { findInitialValuesDetail } from '../utils'
import { CashCropCell } from './CashCropCell'
import { CashCropCellEditor } from './CashCropCellEditor'
import { CashCropDetailSimpleCell } from './CashCropDetailSimpleCell'
import { CashCropEditor } from './CashCropEditor'
import { CashCropResultsOnlyEditor } from './CashCropResultsOnlyEditor'

const TRAIT_ID = 'cashCrop'

export const CashCropResult: ITraitFeatureByIdYear<RDCashCrop> = {
  rollups: {},
  forbidBulkEdit: false,
  traitId: TRAIT_ID,
  editor: CashCropResultsOnlyEditor,
  cellDisplay: CashCropCell,
  SimpleCellView: CashCropDetailSimpleCell,
  ns: '@cibo/landmanager/CashCropEditor',
  validationSchema: (props: Pick<DetailEditorProps, 'requirement'>) =>
    string().oneOf(CASH_CROP_SPECIES).nullable() as StringSchema<RDCashCrop['result']>,

  initialValues: ({ fieldModels, year }) => {
    const cashCrops = fieldModels.length === 1 ? fieldModels[0].cashCrops : undefined
    return cashCrops?.filter(d => d.year === year)?.[0]?.result
  },
  cellEditor: CashCropCellEditor,
  detailFromEditorValues: (formValues, requirement) => {
    return {
      traitId: TRAIT_ID,
      year: requirement?.year,
      result: formValues.input,
    }
  },
}

export const CashCropInput: ITraitFeatureByIdYear<RDCashCrop> = {
  rollups: {},
  forbidBulkEdit: false,
  traitId: TRAIT_ID,
  editor: CashCropEditor,
  cellDisplay: CashCropCell,
  SimpleCellView: CashCropDetailSimpleCell,
  ns: '@cibo/landmanager/CashCropEditor',
  validationSchema: ({ requirement, t }) => {
    return object({
      crop: string<RDCashCropSpecies>().required(t('required')),
      plantedAt: date()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .test(
          'dateRequired',
          t('required_fourDigitYear'),
          // @ts-ignore typing doesn't like this.
          (date: string) => dayjs(date).year() > 1969
        )
        .when('crop', {
          is: 'fallow',
          then: (schema: any) => schema.nullable(),
          otherwise: isDetailRequired({ requirement, fieldName: 'plantedAt' })
            ? (schema: DateSchema) =>
                schema.when('harvestedAt', {
                  is: (val: Date | undefined) => !val || isNaN(val?.valueOf()),
                  then: schema
                    .required(t('required', { context: 'plantedAt' }))
                    .typeError(t('required', { context: 'plantedAt' })),
                  otherwise: schema
                    .max(ref('harvestedAt'), ({ max }) => t('max', { context: 'plantedAt', max }))
                    .required(t('required', { context: 'plantedAt' }))
                    .typeError(t('required', { context: 'plantedAt' })),
                })
            : (schema: any) =>
                schema
                  .nullable()
                  .typeError(t('invalid', { context: 'plantedAt' }))
                  .when('harvestedAt', {
                    is: (val: Date | undefined) => !val || isNaN(val?.valueOf()),
                    then: (schema: any) => schema.nullable(),
                    otherwise: (schema: any) =>
                      schema.max(ref('harvestedAt'), ({ max }: { max: number }) =>
                        t('max', { context: 'plantedAt', max })
                      ),
                  }),
        }),
      harvestedAt: date()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .test(
          'dateRequired',
          t('required_fourDigitYear'),
          // @ts-ignore typing doesn't like this.
          (date: string) => dayjs(date).year() > 1969
        )
        .when('crop', {
          is: 'fallow',
          then: (schema: any) => schema.nullable(),
          otherwise: isDetailRequired({ requirement, fieldName: 'harvestedAt' })
            ? (schema: DateSchema) =>
                schema.when('plantedAt', {
                  is: (val: Date | undefined) => !val || isNaN(val?.valueOf()),
                  then: schema
                    .required(
                      t('required', {
                        context: 'harvestedAt',
                      })
                    )
                    .typeError(
                      t('required', {
                        context: 'harvestedAt',
                      })
                    ),
                  otherwise: schema
                    .min(ref('plantedAt'), ({ min }) =>
                      t('min', {
                        context: 'harvestedAt',
                        min,
                      })
                    )
                    .required(
                      t('required', {
                        context: 'harvestedAt',
                      })
                    )
                    .typeError(
                      t('required', {
                        context: 'harvestedAt',
                      })
                    ),
                })
            : (schema: any) =>
                schema
                  .nullable()
                  .typeError(
                    t('invalid', {
                      context: 'harvestedAt',
                    })
                  )
                  .when('plantedAt', {
                    is: (val: Date | undefined) => !val || isNaN(val?.valueOf()),
                    then: (schema: any) => schema.nullable(),
                    otherwise: (schema: any) =>
                      schema.min(ref('plantedAt'), ({ min }: { min: number }) =>
                        t('min', {
                          context: 'harvestedAt',
                          min,
                        })
                      ),
                  }),
        }),
      harvestType: string().when('crop', {
        is: 'fallow',
        then: (schema: any) => schema.nullable(),
        otherwise: isDetailRequired({ requirement, fieldName: 'plantingRate' })
          ? (schema: any) =>
              schema
                .oneOf(CASH_CROP_HARVEST_TYPES)
                .required(t('required', { context: 'harvestType' }))
                .typeError(t('required', { context: 'harvestType' }))
          : (schema: any) => schema.nullable(),
      }),
      yieldPerAcre: mixed().when('crop', {
        is: 'fallow',
        then: (schema: any) => schema.nullable(),
        otherwise: string().when('harvestType', {
          is: ['grazed', 'none', 'not_available'],
          then: (schema: any) => schema.nullable(),
          otherwise: isDetailRequired({ requirement, fieldName: 'yieldPerAcre' })
            ? (schema: any) => schema.required(t('required'))
            : (schema: any) => schema.nullable(),
        }),
      }),
      residuePercentageRemoved: number().min(0).max(100).nullable(),
      plantingRate: mixed().when('crop', {
        is: 'fallow',
        then: (schema: any) => schema.nullable(),
        otherwise: isDetailRequired({ requirement, fieldName: 'plantingRate' })
          ? (schema: any) => schema.number().required(t('required'))
          : (schema: any) => schema.nullable(),
      }),
      plantingRateUnit: string().when('crop', {
        is: 'fallow',
        then: (schema: any) => schema.nullable(),
        otherwise: isDetailRequired({ requirement, fieldName: 'plantingRateUnit' })
          ? (schema: any) => schema.oneOf(['seedsPerAcre', 'poundsPerAcre']).required(t('required'))
          : (schema: any) => schema.nullable(),
      }),
      herbicideBurndown: boolean().when('crop', {
        is: 'fallow',
        then: boolean().nullable(),
        otherwise: isDetailRequired({ requirement, fieldName: 'herbicideBurndown' })
          ? (schema: any) => schema.required(t('required'))
          : (schema: any) => schema.nullable(),
      }),
      fireBurndown: boolean().when('crop', {
        is: 'fallow',
        then: boolean().nullable(),
        otherwise: isDetailRequired({ requirement, fieldName: 'fireBurndown' })
          ? (schema: any) => schema.required(t('required'))
          : (schema: any) => schema.nullable(),
      }),
    }) as ObjectSchema<RDCashCrop['input']>
  },

  initialValues: request => {
    const detail = findInitialValuesDetail('cashCrop')(request)

    return detail
      ? detail.input
        ? {
            plantedAt: null,
            harvestedAt: null,
            ...detail.input,
          }
        : // @ts-ignore MUI's DatePicker prefers empty string to undefined
          ({
            crop: detail.result,
            plantedAt: null,
            harvestedAt: null,
          } as RDCashCrop['input'])
      : {
          plantedAt: null,
          harvestedAt: null,
        }
  },
}
