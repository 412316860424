import { LIMING_UNIT, RDLiming } from '@cibo/core'
import dayjs from 'dayjs'
import { pathEq } from 'ramda'
import { date, number, object, string } from 'yup'
import { eventValidationSchema } from '../EventDetail'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { LimingCell } from './LimingCell'
import { LimingEditor } from './LimingEditor'
import { LimingRollup } from './LimingRollup'

const TRAIT_ID = 'liming'

export const Liming: ITraitFeatureByIdYear<RDLiming> = {
  traitId: TRAIT_ID,
  cellDisplay: LimingCell,
  editor: LimingEditor,
  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)
    return detail?.input || {}
  },
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: LimingRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          events: [],
        },
      }),
      isRollupValue: pathEq(['input', 'events', 'length'], 0),
    },
  },
  ns: '@cibo/landmanager/LimingEditor',
  validationSchema: eventValidationSchema<RDLiming['input']>(({ t }) =>
    object()
      .shape({
        amount: number()
          .required(t('required', { context: 'amount' }))
          .typeError(t('required', { context: 'amount' })),
        unit: string()
          .oneOf(LIMING_UNIT, t('required', { context: 'unit' }))
          .required(t('required', { context: 'unit' }))
          .typeError(t('required', { context: 'unit' })),
        date: date()
          .required(t('required', { context: 'date' }))
          .test(
            'dateRequired',
            t('required_fourDigitYear'),
            // @ts-ignore typing doesn't like this.
            (date: string) => dayjs(date).year() > 1969
          )
          .typeError(t('required', { context: 'date' })),
      })
      .required(t('required'))
  ),
}
