import { FieldModel, Integer, ResourceDetail } from '@cibo/core'
import { DataGridPro, Markdown, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Box, MenuItem, Select, Stack, styled, Typography } from '@mui/material'
import {
  GridRenderCellParams,
  GridRowHeightReturnValue,
  GridRowId,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fieldColumnAttributes,
  FieldNameCell,
} from '../../../components/FieldColumns/fieldNameColumn'
import { useFields } from '../../../queries'
import { useRemoveDetails, useSaveBulkDetails } from '../../DetailEditing/queries'

const StyledDataGridPro = styled(DataGridPro)(() => ({
  '.MuiDataGrid-cell': {
    padding: 0,
    position: 'relative',
  },
  '.MuiSelect': { boxShadow: 'none' },
  '.MuiDataGrid-pinnedRows': {
    backgroundColor: 'unset',
    boxShadow: 'unset',
  },
}))

export type SelectRollupEditorProps<
  RDT extends ResourceDetail = ResourceDetail<any, any>,
  O extends readonly string[] = readonly string[]
> = {
  detailKey: keyof RDT['input'] | keyof RDT['result'] | 'result'
  ns: string
  options: O
  programId?: string
} & ResourceDetailFeatureTaskEditorProps<RDT>

export const SelectRollupEditor = <RDT extends ResourceDetail, O extends readonly string[]>({
  options,
  detailRequirements: [firstRequirement],
  ns,
  detailKey,
  onError,
  onSuccess,
  onUpdating,
  programId,
  resourceIds,
  userRole,
  ownerName,
}: SelectRollupEditorProps<RDT, O>) => {
  const { t } = useTranslation('@cibo/landmanager/SelectRollupEditor')
  const traitId = firstRequirement.traitId
  const year = firstRequirement.year as Integer
  const detailKeyIsResult = detailKey === 'result'
  const fieldModels = useFields(resourceIds)
  const gridApi = useGridApiRef()
  const [bulkAnswer, setBulkAnswer] = useState<string>()
  const saveBulkDetails = useSaveBulkDetails()
  const removeDetails = useRemoveDetails()

  const answerForDetail = (detail: ResourceDetail) =>
    detailKeyIsResult ? detail?.result : detail?.result[detailKey]

  const detailForAnswer = (answer: string) =>
    detailKeyIsResult
      ? { traitId, programId, year, result: answer }
      : { traitId, programId, year, result: { [detailKey]: answer } }

  const changeBulkAnswer = useCallback(
    (answer: string) => {
      const rowData = gridApi.current.getSortedRows()

      if (answer === 'remove') {
        setBulkAnswer('')
        const fieldsWithDetail: string[] = []

        rowData.forEach(row => {
          if (row.answer !== '') {
            fieldsWithDetail.push(row.id)
            gridApi.current?.updateRows([{ id: row.id, answer: '' }])
          }
        })

        onUpdating?.()
        removeDetails
          .mutateAsync({ resourceIds: fieldsWithDetail, traitId, year, programId })
          .catch(onError)
          .then(onSuccess)
      } else {
        setBulkAnswer(answer)
        const fieldsToUpdate: string[] = []

        rowData.forEach(row => {
          if (row.answer !== answer) {
            gridApi.current?.updateRows([{ id: row.id, answer }])
            fieldsToUpdate.push(row.id)
          }
        })

        onUpdating?.()
        saveBulkDetails
          .mutateAsync({
            resourceIds: fieldsToUpdate,
            details: [detailForAnswer(answer)],
          })
          .catch(onError)
          .then(onSuccess)
      }
    },
    [gridApi.current, setBulkAnswer, removeDetails, saveBulkDetails]
  )

  const changeFieldAnswer = useCallback(
    (id: GridRowId, answer: string) => {
      if (answer === 'remove') {
        gridApi.current?.updateRows([{ id, answer: '' }])

        onUpdating?.()
        removeDetails
          .mutateAsync({ resourceIds: [id as string], traitId, year })
          .catch(onError)
          .then(onSuccess)

        setBulkAnswer('')
      } else {
        gridApi.current?.updateRows([{ id, answer }])

        onUpdating?.()
        saveBulkDetails
          .mutateAsync({
            resourceIds: [id as string],
            details: [detailForAnswer(answer)],
          })
          .catch(onError)
          .then(onSuccess)

        const rowData = gridApi.current.getSortedRows()

        if (rowData.every(row => row.answer === answer)) {
          setBulkAnswer(answer)
        } else {
          setBulkAnswer('')
        }
      }
    },
    [gridApi.current, setBulkAnswer, removeDetails, saveBulkDetails]
  )

  const handleChangeAnswer = useCallback(
    (params: GridRenderCellParams, answer: typeof options[number] | 'remove') => {
      if (params.id === 'bulkAnswerRow') {
        changeBulkAnswer(answer)
      } else {
        changeFieldAnswer(params.id, answer)
      }
    },
    [changeBulkAnswer, changeFieldAnswer]
  )

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: t('field'),
        hideable: false,
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<FieldModel>) => (
          <Box sx={{ marginLeft: 2 }}>
            {params.row.id === 'bulkAnswerRow' ? (
              <Typography sx={{ fontWeight: 'bold', my: 2 }}>{t('answerForAllFields')}</Typography>
            ) : (
              <FieldNameCell {...params} />
            )}
          </Box>
        ),
      },
      {
        field: 'answer',
        headerName: t('answer', { ns, defaultValue: null }) ?? t('answer'),
        flex: 1,
        hideable: false,
        disableColumnMenu: true,
        filterable: false,
        renderCell: (
          params: GridRenderCellParams<
            FieldModel & { answer: any; immutable: boolean; source?: string }
          >
        ) => {
          const { row } = params
          return (
            <Stack spacing={1} direction="row">
              <Select
                value={row.answer}
                onChange={event => handleChangeAnswer(params, event.target.value)}
                placeholder={t('select')}
                data-testid="selectBulkDropdown"
                variant="outlined"
                sx={{ width: '100%' }}
                disabled={row.immutable}
              >
                {row.id === 'bulkAnswerRow' ? (
                  <MenuItem value="remove">
                    <Typography variant="caption">{t('removeAllAnswers')}</Typography>
                  </MenuItem>
                ) : (
                  row.answer !== '' && (
                    <MenuItem value="remove">
                      <Typography variant="caption">{t('remove')}</Typography>
                    </MenuItem>
                  )
                )}

                {options.map(option => (
                  <MenuItem key={option} value={option}>
                    {t(`option_${option}`, { ns })}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          )
        },
      },
    ]
  }, [bulkAnswer, fieldModels.dataUpdatedAt])

  const rows = useMemo(() => {
    const answers = new Set()

    const rows = !fieldModels.data
      ? []
      : fieldModels.data.map(field => {
          const detail = field.resolveStandingDetail(traitId, year)

          const answer = answerForDetail(detail) || ''
          answers.add(answer)

          return {
            ...fieldColumnAttributes(field),
            answer,
            immutable: detail?.immutable,
            source: detail?.source,
          }
        })

    if (answers.size === 1) {
      setBulkAnswer(rows[0].answer)
    }

    return rows
  }, [fieldModels.dataUpdatedAt, removeDetails.isError, saveBulkDetails.isError])

  const getRowHeight = useCallback((): GridRowHeightReturnValue => 'auto', [])

  const isAnyDetailImmutable = !!fieldModels.data?.some(
    field => field.resolveStandingDetail(traitId, year)?.immutable
  )

  return (
    <Stack spacing={3} flex={1}>
      <Markdown
        overrides={{
          em: {
            component: ({ children }: { children: ReactNode }) => (
              <Typography variant="body2" color="text.secondary" component="span">
                {children}
              </Typography>
            ),
          },
        }}
      >
        {[
          t('question', {
            context: userRole,
            name: ownerName,
            ns,
            count: fieldModels.data?.length,
            defaultValue: null,
          }),
          t('optionsExplainer', { ns, defaultValue: null }),
        ]
          .filter(Boolean)
          .join('<br/><br/>')}
      </Markdown>

      <Stack>
        <StyledDataGridPro
          apiRef={gridApi}
          autoHeight
          columns={columns}
          disableRowSelectionOnClick
          getRowHeight={getRowHeight}
          hideCellFocus
          pinnedRows={
            fieldModels.data?.length && fieldModels.data.length > 1
              ? {
                  top: [
                    {
                      id: 'bulkAnswerRow',
                      answer: bulkAnswer || '',
                      immutable: isAnyDetailImmutable,
                    },
                  ],
                }
              : {}
          }
          rows={rows}
        />
      </Stack>
    </Stack>
  )
}
