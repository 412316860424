import { ResourceDetailYearly } from '@cibo/core'

export const PREFERRED_RANK_PRACTICE_OPTION = ['coverCrop', 'noTill', 'nitrogenReduction'] as const
export type PreferredRankPracticeOption = typeof PREFERRED_RANK_PRACTICE_OPTION[number]

export interface RDPreferredRankPractice extends ResourceDetailYearly {
  traitId: 'preferredRankPractice'
  programId: string
  input: {
    ciboValue: {
      practice: PreferredRankPracticeOption
    }
  }
  result: {
    practice: PreferredRankPracticeOption
  }
}
