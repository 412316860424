import { RDEqipReviewRecommendationResults } from '@cibo/core'
import { fieldNameColumn } from '@cibo/landmanager'
import { DataGridPro, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Box, Stack, Typography } from '@mui/material'
import { GridColDef, GridRowHeightParams, GridRowHeightReturnValue } from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PracticesSelectedCell } from '../../../../components/PracticeSelectionConfirmation'
import { TaskMarkdown } from '../../../../features/ProgramEngagementPlayer/Tasks/TaskMarkdown'
import {
  useProgramEngagementDetails,
  useUpdateProgramEngagementDetails,
} from '../../../../hooks/useProgramEngagementDetails'
import { useEqipEngagementFieldsWithConcerns } from '../../../../queries/useEqipRecommendations'
import { DownloadPracticeRecommendationPDF } from './DownloadPracticeRecommendationPDF'
import { ResourceConcernsCell } from './ResourceConcernsCell'

export const RDEqipReviewRecommendationResultsTaskEditor = ({
  detailRequirements,
  engagementId,
  ownerName,
  resourceIds,
  userRole,
  onError,
  onSuccess,
  onUpdating,
}: ResourceDetailFeatureTaskEditorProps<RDEqipReviewRecommendationResults>) => {
  const { t } = useTranslation('@cibo/programs/RDEqipReviewRecommendationResultsTaskEditor')
  const updateDetails = useUpdateProgramEngagementDetails<RDEqipReviewRecommendationResults>()
  const detail = useProgramEngagementDetails<RDEqipReviewRecommendationResults>({
    resourceId: engagementId,
    detailRequirements,
  })

  const fieldsWithConcerns = useEqipEngagementFieldsWithConcerns(engagementId)
  const rows = fieldsWithConcerns.data ?? []

  const columns = useMemo(() => {
    return [
      fieldNameColumn({ t }),
      {
        field: 'resourceConcerns',
        headerName: t('resourceConcerns', { ns: '@cibo/programs/EQIP' }),
        flex: 2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: ResourceConcernsCell,
      },
      {
        field: 'selectedPractices',
        headerName: t('practicesSelected', { ns: '@cibo/programs/EQIP' }),
        flex: 2,
        sortable: false,
        filterable: false,
        renderCell: PracticesSelectedCell,
      },
    ] as GridColDef[]
  }, [detailRequirements?.[0]?.year])

  const getRowHeight = useCallback(
    (p: GridRowHeightParams) => 'auto' as GridRowHeightReturnValue,
    []
  )

  const onDownload = useCallback(() => {
    if (!detail.data?.[0]?.immutable) {
      onUpdating?.()
      updateDetails
        .mutateAsync({
          details: [
            {
              traitId: detailRequirements[0].traitId,
              result: true,
            } as RDEqipReviewRecommendationResults,
          ],
          resourceId: engagementId,
        })
        .then(onSuccess)
        .catch(onError)
    }
  }, [detail.data?.[0]?.immutable])

  return (
    <Stack spacing={3}>
      <Stack spacing={1.5}>
        <Typography variant="h6">{t('practicesSelected')}</Typography>
        <Stack direction="row" justifyContent="space-between" spacing={3}>
          <Typography color="text.secondary">
            {t('question', { context: userRole, name: ownerName, count: resourceIds.length })}
          </Typography>
          <Box sx={{ flexWrap: 'wrap' }}>
            <DownloadPracticeRecommendationPDF
              engagementId={engagementId}
              onDownload={onDownload}
              sx={{ whiteSpace: 'nowrap' }}
              variant="contained"
            >
              {t('downloadResults')}
            </DownloadPracticeRecommendationPDF>
          </Box>
        </Stack>
      </Stack>
      <DataGridPro
        rows={rows}
        getRowHeight={getRowHeight}
        columns={columns}
        loading={fieldsWithConcerns.isPending}
        autoHeight
      />
      <TaskMarkdown>{t('linksMd')}</TaskMarkdown>
    </Stack>
  )
}
