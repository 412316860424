import { EqipSelectedPracticesPDF } from '@cibo/programs'
import { Route, Routes } from 'react-router-dom'

export const PdfTemplates = () => {
  return (
    <Routes>
      <Route path="/eqip-selected-practices/:engagementId" element={<EqipSelectedPracticesPDF />} />
    </Routes>
  )
}
