import { AMENDMENT_METHOD, AMENDMENT_TYPE, AMENDMENT_UNITS, RDAmendment } from '@cibo/core'
import dayjs from 'dayjs'
import { pathEq } from 'ramda'
import { date, number, object, string } from 'yup'
import { eventValidationSchema } from '../EventDetail'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { AmendmentCell } from './AmendmentCell'
import { AmendmentEditor } from './AmendmentEditor'
import { AmendmentRollup } from './AmendmentRollup'

const TRAIT_ID = 'amendment'

export const Amendment: ITraitFeatureByIdYear<RDAmendment> = {
  traitId: TRAIT_ID,
  cellDisplay: AmendmentCell,
  editor: AmendmentEditor,
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: AmendmentRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          events: [],
        },
      }),
      isRollupValue: pathEq(['input', 'events', 'length'], 0),
    },
  },
  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)
    return detail?.input || {}
  },
  ns: '@cibo/landmanager/AmendmentEditor',
  validationSchema: eventValidationSchema<RDAmendment['input']>(({ t }) =>
    object()
      .shape({
        date: date()
          .required(t('required_date'))
          .test(
            'dateRequired',
            t('required_fourDigitYear'),
            // @ts-ignore typing doesn't like this.
            (date: string) => dayjs(date).year() > 1969
          )
          .typeError(t('required_date')),
        amendmentType: string().oneOf(AMENDMENT_TYPE).required(t('required_amendmentType')),
        method: string().oneOf(AMENDMENT_METHOD).required(t('required_method')),
        rate: number().required(t('required_rate')),
        units: string().oneOf(AMENDMENT_UNITS).required(t('required_units')),
      })
      .required()
  ),
}
