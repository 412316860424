import { Termset } from '@cibo/core'
import { PlatformUser } from '@cibo/core/src/types/Program/Config'
import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material'
import { useField } from 'formik'
import { propEq } from 'ramda'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePaginatedFarms } from '../../../queries'

export const FarmFilter = () => {
  const { t } = useTranslation('@cibo/landmanager/FarmFilter')
  const [fullText, setFullText] = useState('')
  const farms = usePaginatedFarms({
    fullText,
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { value = null }, { setValue }] = useField('termsets')

  const handleChangeFarm = (_: any, newValue: Termset<PlatformUser>[]) => {
    setValue(newValue.map(({ id }: Termset<PlatformUser>) => id))
  }

  const renderValue = useMemo(
    // @ts-ignore appeared when changing value of `handleChangeFarm` above
    () => (value ? value.map((id: string) => farms.data?.items.find(propEq('id', id))) : []),
    [value]
  )

  return (
    <FormControl>
      <FormLabel>{t('farm')}</FormLabel>

      <Autocomplete
        multiple
        filterOptions={o => o}
        loading={farms.isPending}
        options={farms.data?.items.filter(item => !!item) || []}
        getOptionLabel={option => option?.name}
        onInputChange={(_, newFullText) => setFullText(newFullText)}
        renderOption={(props, option) => <li {...props}>{option?.name}</li>}
        renderInput={params => (
          <TextField {...params} placeholder={farms.isError ? t('farmError') : t('farm')} />
        )}
        value={renderValue}
        onChange={handleChangeFarm}
        size="small"
        fullWidth
        disabled={farms.isError}
      />
    </FormControl>
  )
}
